// .booking-inprog{
//     &__popup{
//         z-index: 20;
//         background-color: #000000;
//         opacity: 0.6;
//         width: 100%;
//         height: 100%;
//         left: 0;
//         top: 0;
//         position: fixed;
//     }
//     &__popup-body{
//         z-index: 21;
//         background-color: white;
//         width: 100%;
//         position: fixed;
//         opacity: 1;
//         padding-top: 20px;
//         & .row{
//             padding: 0 0 20px;
//         }
//     }
// }

.booking-inprog {
    padding-right: 0px !important;
    padding-left: 0px !important;

    & .row {
        padding: 0 0 20px;
    }
    &__textline1 {
        text-align: center;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .booking-inprog {
        max-width: 600px;
    }
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
