

.booking__review{
    padding: 0 0 15px 0;
}

.booking__review-payment{
    & .row{
        margin-bottom: 8px;
    }
}

.booking__payment{
    padding: 0 0 15px 0;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

    & #payment-form{
        flex: 1 1 auto;        
    }

    .StripeElement{
        margin: 0 0 1rem 0;        
    }

    .card-error{
        color: red;
    }
}


.booking__confirmation{
    padding: 10px 0 0 0;
    
    & .row{
        margin-bottom: 8px;
        font-size: 14px;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    
.booking__payment{
   

    & #payment-form{
        flex: 0 1 auto;        
    }
}


}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
