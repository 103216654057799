.booking-massages {
   

    &__massage {
        width: 100%;
        text-align: center;
        // background-color: #eaeaea;
        margin: 0 0 20px 0;
        position: relative;
        padding: 0px;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        background-color: #f2f2f2;
        justify-content: flex-end;

        & img {
            border-radius: 0px;
            flex: 0 1 auto;
            width: 100%;
            object-fit: cover;
            height: 200px;
            object-position: center center;
        }
        & div {
            font-size: 14px;
            // font-weight: bold;
            flex: 0 1 auto;
            padding: 4px;
            padding: 10px;
            background-color: #eaeaea;
        }
        & .--selected{          
            background-color: #eaeaea;
            position: absolute;
            width: 100%;
            height: 100%;
            // flex: 1 1 auto;
            left: 0;
            top: 0;
            opacity: 0.5;
        }
        & .check-icon{
            color: green;
            z-index: 2;
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }
}


/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .booking-massages {  
        &__massage {
            margin: 0 0 20px 0;
            flex: 1 1 auto;
            max-width: 325px;
        }
    }
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .booking-massages {  
        &__massage {
            max-width: 380px;
        }
    }
}
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
