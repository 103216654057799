.home {
    padding: 0 !important;
    &__title1,
    &__title2 {
        line-height: 46px !important;
        font-weight: 500;
        text-align: center;

        // background-color: #414242;
        padding: 20px;
        // color: white;
        font-family: "Raleway";
        letter-spacing: 3px;
        margin: 0;
        text-transform: uppercase;
        font-size: 30px !important;
        padding: 0;
    }
    &__title2 {
        line-height: 36px !important;
        font-weight: 600;
        font-size: 18px !important;
    }
    & p {
        font-size: 16px;
        line-height: 30px;
    }
    &__image {
        &-title1.-animation-start {
            animation-name: header-title1;
            animation-duration: 1s;
        }
        &-title2.-animation-start {
            animation-name: header-title2;
            animation-duration: 1s;
        }
    }
    &__image {
        height: 250px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;

        &.-background {
            background-image: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.27) 0%, rgba(0, 0, 0, 0.27) 100%),
                url("https://assets-sabai-sabi-thai-therapist.s3.eu-west-2.amazonaws.com/assets/home/home1.jpg");
            & .home__image-title1 {
                animation-name: header-title1;
                animation-duration: 1s;
                animation-fill-mode: forwards;
            }
            & .home__image-title2 {
                animation-name: header-title2;
                animation-duration: 1s;
                animation-delay: 1.5s;
                animation-fill-mode: forwards;
            }
        }

        &-titlecontainer {
            display: flex;
            flex: 1 1 auto;
            max-width: 1140px;
            flex-direction: column;
            align-self: center;
            padding: 0 10px;
        }

        &-title1 {
            // font-size: 35px !important;
            font-size: 18px !important;
            text-transform: uppercase;
            // margin-bottom: 32px;
            margin-bottom: 5px;
            font-family: "Raleway";
            letter-spacing: 2px;
            color: white;

            position: relative;
            opacity: 0;
            left: 0px;
        }
        &-title2 {
            // font-size: 48px !important;
            font-size: 22px !important;
            text-transform: uppercase;
            color: white;
            font-family: "Raleway";
            font-weight: 400;
            //letter-spacing: 5px;
            letter-spacing: 2px;
            opacity: 0;
            left: 0px;
            position: relative;
            // line-height: 43px !important;
            line-height: 31px !important;
        }

        & img {
            position: absolute;
            width: 100%;
        }

        &-overlay-bg {
            background-color: gray;
        }
    }

    &__section1,
    &__section2 {
        // background-color: #fcfcfc;
        //#F9F9F9
        padding: 20px 40px;

        &-container {
        }
    }

    &__section1, & .massage-section {
        background-color: #f9f9f9;
    }

    &-massages {
        display: flex;
        // flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;

        &__massage {
            // width: 33.3%;
            // justify-content: center;
            // margin: 0 0 40px;
            // display: flex;

            width: 300px;
            justify-content: center;
            margin: 0 0 40px;
            display: flex;
            border-radius: 150px;
            /* max-width: 33.3%; */
            /* border: 1px solid red; */
            overflow: hidden;
            height: 300px;
            position: relative;
            cursor: pointer;

            &-overlay {
                opacity: 0.4;
                background-color: black;
                position: absolute;
                width: 100%;
                height: 100%;
                // transition: opacity 0.2s linear;
            }

            &-info {
                position: absolute;
                display: flex;
                align-self: flex-end;
                flex-direction: column;
                // top: 225px;
                flex: 1 1 auto;
                width: 100%;
                align-items: center;
                // height: 200px;
                // background-color: black;
                // opacity: 0.8;
                transition: top 0.2s, opacity 0.2s;
                align-items: center;
            }

            &-name {
                color: white;
                // margin: 5px 0 10px 0;
                // color: white;
                /* margin: 5px 0 10px 0; */
                font-size: 18px !important;
                max-width: 210px;
                text-align: center;
                flex: 1 1 auto;
                /* display: flex; */
                /* align-self: flex-end; */
                /* justify-content: center; */
                margin: 0;
            }

            & img {
                width: 100%;
                object-fit: cover;
                // width: 300px;
                // height: 300px;
                // border-radius: 150px;
            }

            // &-img{
            //     width: 300px;
            //     height: 300px;
            //     border-radius: 150px;

            // }

            &-readmore {
                background-color: black;
                color: white;
                // margin: 0 0 20px;
                border: 0;
                opacity: 1;
                display: flex;
                margin: 4px 0 20px;
                // transition: opacity 0.2s;
            }

            &:hover {
            }
        }
    }

    &-booknow {
        height: 400px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        background-image: url("https://assets-sabai-sabi-thai-therapist.s3.eu-west-2.amazonaws.com/assets/home/contactus.jpg");

        &__titlecontainer {
            display: flex;
            flex: 1 1 auto;
            max-width: 1140px;
            flex-direction: column;
            align-self: center;
        }

        &__title1 {
            font-size: 13px !important;
            text-transform: uppercase;
            margin-bottom: 32px;
            font-family: "Raleway";
            letter-spacing: 2px;
            color: white;
            text-align: center;
            margin: 0;
        }
        &__title2 {
            // font-size: 48px !important;
            font-size: 28px !important;
            text-transform: uppercase;
            color: white;
            font-family: "Raleway";
            font-weight: 400;
            letter-spacing: 5px;
            text-align: center;
            line-height: 43px !important;
        }
        &__action {
            display: flex;
            margin: 10px 0 0;
            align-self: center;
        }
    }

    &-contactus {
        display: flex;
        flex-direction: column;
        align-items: center;
        &__title {
            line-height: 46px;
            font-weight: 500;
            text-align: center;
            padding: 20px;
            font-family: "Raleway";
            letter-spacing: 3px;
            margin: 0;
            text-transform: uppercase;
            font-size: 30px !important;
        }

        &__line1 {
            max-width: 800px;
            text-align: center;
        }
    }
}
/*
@-webkit-keyframes example {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-moz-keyframes example{
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-o-keyframes example {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}*/
@media (hover: hover) {
    .home-massages__massage-info {
        top: 225px;
        opacity: 0.8;
    }
    .home-massages__massage-overlay {
        opacity: 0.2;
        transition: opacity 0.2s linear;
    }

    .home-massages__massage:hover {
        & .home-massages__massage-readmore {
            opacity: 1;
        }

        & .home-massages__massage-info {
            top: 170px;
            opacity: 1;
        }

        & .home-massages__massage-overlay {
            opacity: 0.6;
        }
    }

    .home-massages__massage-readmore {
        opacity: 0;
        transition: opacity 0.2s;
    }
}


    @keyframes header-title1 {
        0% {
            opacity: 0;
            // left: -200px;
            transform: scale(2);
            // transform: scale(0.5);
        }
        100% {
            opacity: 1;
            // left: 0;
            // transform: scale(1);
            transform: scale(1);
        }
    }

    @keyframes header-title2 {
        0% {
            opacity: 0;
            //transform: scale(2);
            left: -200px;
        }
        100% {
            opacity: 1;
            //transform: scale(1);
            left: 0;
        }
    }


/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    .home {
        max-width: 100%;

        &-massages{
            justify-content: space-between;
        }
    }
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .home {
        &__image {
            &-titlecontainer {
                padding: 0 40px;
            }
            &-title1 {
                margin-bottom: 12px;
                font-size: 22px !important;
            }
            &-title2 {
                font-size: 32px !important;
            }
        }
    }
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .home {
        &__image {
            height: 450px;
            &-title1 {
                margin-bottom: 12px;
                font-size: 27px !important;
            }
            &-title2 {
                font-size: 42px !important;
                line-height: 34px !important;
            }
        }

        &-booknow {
            &__title2 {
                font-size: 38px !important;
            }
        }

        &-massages {
            &__massage {
                width: 250px;
                height: 250px;
                border-radius: 125px;
                &-readmore{
                    margin: 4px 0 20px;
                }
            }
        }
    }
}
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .home {
        max-width: 100%;
        &__section1,
        &__section2 {
            &-container {
                max-width: 1140px;
                margin: 0 auto;
            }
        }
        &__image {
            height: 650px;
            &-titlecontainer {
                max-width: 1140px;
                padding: 0;
            }
            &-title1 {
                margin-bottom: 32px;
                font-size: 35px !important;
            }
            &-title2 {
                font-size: 48px !important;
                line-height: 43px !important;
            }
        }

        &-booknow {
            &__title2 {
                font-size: 48px !important;
            }
        }

        &-massages {
            &__massage {
                width: 300px;
                height: 300px;
                border-radius: 150px;
                &-name{
                    margin: 5px 0 10px 0;
                    font-size: 18px !important;
                    // max-width: 235px;
                }
                &-info{
                    height: 115px;

                }
            }
            
        }
    }
}
