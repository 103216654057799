/* Provide sufficient contrast against white background */

html {
  height: 100%;
}

*:focus {
  outline: none !important;
}

body,
div#root {
  height: inherit;
  font-family: "Roboto", sans-serif;
  color: #000000;
  /* background-color: rgba(0, 0, 0, 0.05); */
}

div#root {
  display: flex;
  /* flex: 1 1 auto;
  height: inherit; */
  flex-direction: column;
}

a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

p {
  font-size: 14px;
  line-height: 23px;
  color: #414242;
}

/* .header-menu {
  
} */
.top-nav__toggler {
  /* padding: 0.25rem 0.75rem; */
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: none;
  border-radius: 0.25rem;
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
  flex: 0 0 auto;
  padding: 0;
}
.top-nav__toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  /* background: no-repeat center center; */
  background-size: 100% 100%;
}

.top-nav__bar {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  align-items: center;
}

.top-nav__title {
  font-size: 1.4em;
  flex: 1 1 auto;
  text-align: center;
}

.site-link {
  display: flex;
}

.top-nav__title a,
.top-nav__title a:visited {
  /* color: #185cdb !important; */
  font-family: "Dancing Script";
  font-weight: 600;
  font-size: 30px;
  padding: 0;
}

.top-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav-item {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
}

.nav-icon {
  transform: scale(1.3);
  color: rgba(0, 0, 0, 0.5);
  display: none;
}

.box-shadow {
  /* box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05); */
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
    background-color: rgba(0, 0, 0, 0.05);
}

.slide-menu {
  background-color: white;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  max-width: 360px;
  overflow: hidden;
  position: fixed;
  top: 0;
  transform: translate3d(-100%, 0, 0);
  transition-property: opacity, transform, visibility;
  height: 100%;
  width: 100%;
  z-index: 3;
  transition-duration: 500ms;
  transition-timing-function: var(--venia-anim-out);
}

.slide-menu.--visible {
  box-shadow: 1px 0 rgb(var(--venia-border));
  transform: translate3d(0, 0, 0);
}

.slide-menu__bg {
  background-color: black;
  cursor: pointer;
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition-duration: 192ms;
  transition-property: opacity, visibility;
  transition-timing-function: linear;
  visibility: hidden;
  width: 100%;
  z-index: 2;
  -webkit-appearance: none;
}

.slide-menu__bg.--visible {
  opacity: 0.5;
  transition-duration: 224ms;
  visibility: visible;
}

.slide-menu .signin {
  padding: 0.5rem 0;
  margin: 0.5rem 1rem 1.2rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #cccccc;
}

.slide-menu .signin.-not {
  margin: 0.5rem 1rem 1.2rem 0;
}

.slide-menu .signin__icon {
  color: black;
}

.slide-menu .slide-menu-close__icon {
  color: #cacaca;
  transform: scale(1.5);
  position: absolute;
  right: 30px;
}

.form-control.is-invalid {
  background-image: none !important;
}

.slide-menu .signin__title {
  font-size: 16px;
  font-weight: bold;
  padding: 0 1rem;
}

.slide-menu .nav-link {
  font-size: 16px;
  color: black;
}

.slide-menu .nav-link.--christmas {
  color: red !important;
}

body[data-scroll-lock="true"] {
  height: 100%;
  overflow: hidden;
  position: fixed;
  width: 100%;
}

.header-menu {
  padding-right: 40px;
  padding-left: 40px;
  display: none;
}

.header-menu__row {
    align-items: center;
    margin: 0;
    padding: 0;
}


.page-container {
  /* margin: 0; */
  /* padding: 1rem 0; */
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  /* padding-right: 40px;
  padding-left: 40px; */
  /* background-color: white; */
  box-shadow: none;
}

h1 {
  text-align: center;
  background: none;
  font-size: 22px;
  padding: 16px 0px;
  font-weight: 400;
  text-transform: none;
  font-family: 'Raleway';
}

/* h2{
} */

.aboutus .image1 {
  /* background-image: url(https://sabaitherapist.co.uk/wp-content/uploads/2019/02/fotolia-20292246-_12-20111203_084603-42-1024x512.jpg); */
  background-image: url("https://assets-sabai-sabi-thai-therapist.s3.eu-west-2.amazonaws.com/assets/home/home1.jpg");
  min-height: 200px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 0 1rem 0;
}

header {
  padding: 0.5rem 0;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
  /* background-color: rgba(0, 0, 0, 0.05); */
}

/* .home-image {
  
  background-image: url("/images/home/home1.jpg");

  height: 250px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
} */

/* .home-carousel{
  height: 250px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
} */


.home .image1 p {
  font-size: 24px;
  color: white;
  padding: 1rem;
}
/* .home .image1 img{
  opacity: 0.7;
} */
.third-party-join__line {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #cdcfd2;
  vertical-align: middle;
}
.third-party-join__or-span {
  padding: 0 20px;
  background: white;
}
.third-party-join {
  /* margin: 1rem 0; */
}
.third-party-join__line-wrapper {
  /* margin: 0 0 1rem 0; */
  width: 100%;
}

.third-party-join__content {
  position: absolute;
  left: 0;
  width: 100%;
}
.third-party-join__fb-icon {
  /* position: absolute; */
  display: inline-block;
  height: 40px;
  width: 40px;
  background: url(https://static-exp1.licdn.com/sc/h/7y4vm785suau3ad8ch5j8yq3w) center/40px no-repeat;
}
.contact-us-info {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.third-party-join__fb-wrapper {
  height: 40px;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #425597;
  box-shadow: 0 3px 5px #150f6b;
  border: none;
}

.third-party-join__google-wrapper {
  height: 40px;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;
  /* color: #444; */
  border: none;
  box-shadow: 0px 3px 5px grey;
  border-radius: 5px;
  /* border: thin solid #888; */
}

.site-link__signin-icon{
  margin: 3px 5px 0 0;
}

/* .third-party-join__google-icon {
  display: inline-block;
  height: 40px;
  width: 40px;
  background: url(/images/btn_google_light_pressed_ios.svg) center/40px no-repeat;
} */

/* .customGPlusSignIn #customBtn {
  display: inline-block;
  background: white;
  color: #444;
  width: 190px;
  border-radius: 5px;
  border: thin solid #888;
  box-shadow: 1px 1px 1px grey;
  white-space: nowrap;
}
.customGPlusSignIn #customBtn:hover {
  cursor: pointer;
} */
/* .customGPlusSignIn span.label {
  font-weight: normal;
}
.customGPlusSignIn span.icon {
  background: url(/images/btn_google_light_pressed_ios.svg);

  width: 26px;
  height: 26px;

  background-position-y: -10px;
  background-position-x: -10px;
  background-repeat: no-repeat;
}
.customGPlusSignIn span.buttonText {
  display: inline-block;
  vertical-align: middle;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 14px;
  
  color: #6d6d6d;
} */


/* 
@media (min-width: 400px) { 
  .top-nav__title{
    font-size: 1.4em;
    line-height: 1.1em;    
  }
  .top-nav__title .nav-link{
    padding: 0.5rem 0rem !important;
  }
  .nav-icon {
    display: inline-block;
    margin-left: 0.5rem !important;
  }
 } */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .top-nav__title a,
  .top-nav__title a:visited {
    font-weight: 700;
    font-size: 30px;
    text-align: left;
  }
  .header-menu {
    padding-right: 0;
    padding-left: 0;
    display: block;
  }
  .page-container {
    /* padding-right: 0;
    padding-left: 0; */
  }
  /* .home-carousel, .home-image{
    height: 350px;
  } */
  .top-nav{
    max-width: 100%;
  }
  .site-link{
    padding: 0 10px;
  }
  h1 {
    text-align: center;
    background: none;
    font-size: 30px;
    padding: 16px 20px;
    font-weight: 400;
    text-transform: none;
    font-family: 'Raleway';
  }
  header{
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
    }
    .top-nav__toggler {
        display: none;
  }
}
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .top-nav__title a,
  .top-nav__title a:visited {
    font-weight: 700;
    font-size: 37px;
    text-align: left;
  }
  .top-nav {
    max-width: 1140px;    
  }
  /* .home-carousel, .home-image{
    height: 550px;
  } */
  .site-link{
        
  }
  .page-container{
    /* box-shadow: 0 0 5.75rem rgba(0, 0, 0, 0.05); */
  }
}
