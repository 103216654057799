.offer-container{
    background-color: #811717;
    text-align: center;
    padding: 10px;
    color: white !important;
    font-weight: 300;

    &:hover, &:focus{
        color: white !important;
    }
}