.aboutus-review {
    display: flex;
    // flex-direction: column;
    // margin: 0 0 18px 0;
    // border-bottom: 1px solid #f9f9f9;
    height: 400px;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;

    &__author-pic {
        margin: 0 12px 0 0;

        & img {
            width: 50px;
        }
    }

    &__author {
        font-weight: 600;
        font-size: 16px;
        margin: 0 0 6px 0;
    }

    &__rating {
        display: flex;
        margin: 0 0 6px 12px;
        align-items: center;
        text-align: center;
        justify-content: center;
    }

    &__star {
        color: #fbbc04;
        font-size: 14px;
    }

    &__time {
        font-size: 14px;
        margin: 0 0 0 12px;
    }

    &__text {
        max-width: 800px;
        //position: relative;
        padding: 0 20px;
        // max-height: 200px;

        // &::after {
        //     content: "...";
        //     position: absolute;

        //     right: 5px;
        //     bottom: 5px;
        // }
        &.--rest{
            display: none;
        }
    }

    &__info {
        display: flex;
        margin: 12px 0 0 0;
        flex-wrap: wrap;
        /* align-items: center; */
        justify-content: center;
    }
}


/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

    .aboutus-review__text.--mobileOnly{
        display: none;
    }
    .aboutus-review__text.--rest{
        display: block;
    }
}
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
