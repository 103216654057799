.booking {
    // background-color: white;
    // position: fixed;
    // top: 0;
    // width: 100%;
    // height: 100%;
    // z-index: 12;
    // left: 0;
    display: flex;
    flex: 1 1 auto;
    padding: 0.5rem 20px;
    flex-direction: column;
    align-items: center;

    // &__bg{
    //     background-color: gray;
    //     opacity: 0.7;
    //     position: fixed;
    //     top: 0;
    //     width: 100%;
    //     height: 100%;
    //     z-index: 10;
    //     left: 0;
    // }

    // & .close-icon{
    //     z-index: 12;
    //     position: fixed;
    //     top: 20px;
    //     right: 20px;
    //     color: black;
    // }

    & .close-icon {
        // z-index: 12;
        // position: fixed;
        // top: 20px;
        // right: 20px;
        color: black;
    }

    &__header {
        display: flex;
        // flex-direction: row;
        align-items: center;
        margin: 0 0 20px 0;

        &-title {
            font-size: 16px;
            font-weight: bold;
            text-transform: uppercase;
        }
    }

    &__cancel {
        flex: 1 1 auto;
        display: inherit;
        justify-content: flex-end;
    }

    &__body {
        // background-color: white;

        // position: fixed;
        // top: 0;
        // // width: 100%;
        // // height: 100%;
        // z-index: 12;
        // left: 0;
        // margin: 50px;
        display: flex;
        flex: 1 1 auto;
        width: 100%;
        justify-content: center;
        // padding: 20px 0;
    }

    &__step {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
    }

    &__navigation {
        display: flex;
        flex: 1 1 auto;
        // justify-content: space-between;
        margin: 0 -10px;
        align-items: flex-end;
    }

    &__employees,
    &-massages,
    &__calendar,
    &__customer,
    &__review-payment,
    &__confirmation {
        display: flex;
        flex: 0 1 auto;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    &__calendar,
    &__customer,
    &__review-payment,
    &__confirmation {
        flex-direction: column;
    }

    &__customer,
    &__review-payment {
        flex: 1 1 auto;
        width: 100%;
    }

    &__employee {
        width: 46%;
        padding: 10px;
        text-align: center;
        background-color: #eaeaea;
        margin: 0 0 20px 0;
        position: relative;
        cursor: pointer;

        & img {
            width: 100% !important;
            max-height: 200px !important;
            max-width: 200px !important;
            border-radius: 100px;
        }
        & .--selected {
            background-color: #eaeaea;
            position: absolute;
            width: 100%;
            height: 100%;
            // flex: 1 1 auto;
            left: 0;
            top: 0;
            opacity: 0.5;
        }
        & .check-icon {
            color: green;
            z-index: 2;
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }

    &__step-title {
        display: flex;
        flex: 0 1 auto;
        font-size: 16px;
        margin: 20px 0 10px;
        font-weight: bold;
    }

    &__button {
        // display: flex;
        margin: 0 10px;
        flex: 1 1 auto;
        // justify-content: center;

        &.--pay {
            background-color: green;
        }
    }

    &__progress {
        display: flex;
        // flex-direction: row;
        height: 10px;
        // border: 1px solid red;
        // justify-content: space-between;
        width: 100%;
        margin: 0 0 1rem 0;

        & div {
            background-color: #eaeaea;
            flex: 1 1 auto;
            margin: 2px;

            &.--inprogress {
                background-color: green;
            }

            &.--completed {
                background-color: gray;
            }
        }
    }

    // &__title{

    // }

    // &__time-slots{
    //     display: flex;
    //     flex-wrap: wrap;
    //     margin: 0 -5px;

    //     & input[type=button] {
    //         width: 20%;
    //         margin: 5px;
    //         font-size: 12px;
    //     }
    // }

    // & .time-slot__button{
    //     border: 0;
    // /* background-color: green; */
    // font-size: 14px;
    // margin: 5px;
    // /* padding: 4px 8px; */
    // flex: 1 1 auto;
    // width: 20%;
    // max-width: 86px;
    // }

    .booking__employees-info {
        font-size: 14px;
        margin: 0 0 10px;
    }

    & .--in-progress {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .loader {
        border: 2px solid #f3f3f3; /* Light grey */
        border-top: 2px solid #3498db; /* Blue */
        border-radius: 50%;
        width: 20px;
        height: 20px;
        animation: spin 2s linear infinite;
        margin: 0 0 0 10px;
        &.--large {
            border: 20px solid #f3f3f3; /* Light grey */
            border-top: 20px solid #3498db; /* Blue */
            width: 120px;
            height: 120px;
            z-index: 20;
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .booking {
    //     &__customer,
    //     &__review-payment {
    //         flex: 1 1 auto;
    //         max-width: 600px;
    //     }
        &__step.step4, &__step.step5, &__step.step6{
            max-width: 600px;
        }
        &__navigation{
            align-items: flex-start;
        }
     }
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .booking {
        &__body {
            max-width: 800px;
        }
    }
}
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
