.massages {
    display: flex;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;

    &-container {
        display: flex;
        flex: 1 1 auto;
        flex-wrap: wrap;
    }

    &__massage {
        width: 100%;
        background-color: #ffffff;
        padding: 20px 0;

        &.-alt {
            background-color: #f2f2f2;
        }

        & h5 {
            padding: 0 20px 10px;
            text-align: center;
        }

        &-desc {
            padding: 20px 20px 0;
        }

        &-action {
            padding: 0 20px;
        }

        & img {
            border-radius: 0px;
            flex: 0 1 auto;
            width: 100%;
            object-fit: cover;
            height: 200px;
            object-position: center center;
        }
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .massages {
        &-container {
            margin: 0 10px;
        }

        &__massage {
            width: 50%;
            padding: 0;
            display: flex;
            flex-direction: column;
            background-color: #ffffff;

            &.-alt {
                background-color: #ffffff;
            }

            &-desc {
                padding: 20px 0px 0;
            }

            &-action {
                display: flex;
                flex: 1 1 auto;
                align-items: flex-end;
                padding: 0;
            }

            &-wrapper {
                padding: 20px;
                background-color: #f2f2f2;
                border-radius: 10px;
                margin: 10px;
                display: flex;
                flex: 1 1 auto;
                flex-direction: column;
            }
        }
    }
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .massages {
        &__massage {
            width: 33.33%;
        }
    }
}
