.quick-book{
    background-color: white;
        
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 12;
    left: 0;
    // margin: 50px;
    display: flex;
    flex: 1 1 auto;
    padding: 15px 20px;
    flex-direction: column;


    // &__bg{
    //     background-color: gray;
    //     opacity: 0.7;
    //     position: fixed;
    //     top: 0;
    //     width: 100%;
    //     height: 100%;
    //     z-index: 10;
    //     left: 0;
    // }

    // & .close-icon{
    //     z-index: 12;
    //     position: fixed;
    //     top: 20px;
    //     right: 20px;
    //     color: black;
    // }

    & .close-icon{
        // z-index: 12;
        // position: fixed;
        // top: 20px;
        // right: 20px;
        color: black;
    }

    &__header{
        display: flex;
        // flex-direction: row;
        align-items: center;
        margin: 0 0 20px 0;

        &-title{
            font-size: 16px;
            font-weight: bold;
            text-transform: uppercase;
        }
    }

    &__cancel {
        flex: 1 1 auto;
    display: inherit;
    justify-content: flex-end;
    }

    &__body{
        // background-color: white;
        
        // position: fixed;
        // top: 0;
        // // width: 100%;
        // // height: 100%;
        // z-index: 12;
        // left: 0;
        // margin: 50px;
        display: flex;
        flex: 1 1 auto;
    }

    &__step{
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
    }

    &__navigation{
        display: flex;
        flex: 1 1 auto;
        // justify-content: space-between;
        margin: 0 -10px;
        align-items: flex-end;
    }

    &__people{
        display: flex;
        flex: 0 1 auto;
        flex-wrap: wrap;
        // margin: 0 -4%;
        justify-content: space-between;
    }

    &__person {
        width: 46%;
        padding: 10px;
        text-align: center;
        background-color: #eaeaea;
        // margin: 2%;
        margin: 0 0 20px 0;

        & img{
            width: 100% !important;
            max-height: 200px !important;
            max-width: 200px !important;
            border-radius: 100px;
        }
    }

    &__step-title{
        display: flex;
        flex: 0 1 auto;
        font-size: 16px;
        margin: 20px 0 10px;
        font-weight: bold;
    }

    &__button{
        // display: flex;
        margin: 0 10px;
        flex: 1 1 auto;
        // justify-content: center;
    }

    &__progress{
        display: flex;
        // flex-direction: row;
        height: 10px;
        // border: 1px solid red;
        // justify-content: space-between;

        & div{
            background-color: orange;
            flex: 1 1 auto;
            margin: 2px;
        }
    }
}