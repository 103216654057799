.reviews{
    // background-color: white;
    display: flex;
    flex: 1 1 auto;
    padding: 0;
    flex-direction: column;

    &-container{
        padding: 20px;
    }

    &-actions{
        display: flex;     
        justify-content: space-between; 
        margin: 0 -6px;
        
        & button{
            flex: 1 1 auto;
            margin: 0 6px;
        }
    }
}

.review-view{
    display: flex;
    // flex-direction: column;
    // margin: 0 0 18px 0;
    // border-bottom: 1px solid #f9f9f9;

    &__author-pic {
        margin: 0 12px 0 0;

        & img{
            width: 50px;
        }        
    }

    &__author{
        font-weight: 600;
        font-size: 16px;
        margin: 0 0 6px 0;
    }

    &__rating{
        display: flex;
        margin: 0 0 6px 0;
        align-items: center;
    }

    &__star{
        color: #fbbc04;
        font-size: 14px;
    }

    &__time{
        font-size: 14px;
        margin: 0 0 0 12px;

    }
}

