.contact-us {
    & .text-line1 {
        margin: 0;
        text-align: center;
    }

    & p {
        font-size: 16px;
        line-height: 27px;
    }

    &__section1 {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 20px 0 0 0;
    }

    &__info {
        // flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        & p {
            margin: 0 0 6px 0;
            line-height: 18px;
            font-size: 16px;
        }
    }

    &__map {
        width: 100%;
        height: 350px;
        margin: 40px 0 20px;
    }

    &__info-section {
        flex: 1 1 50%;
        background-color: #f2f2f2;
        padding: 20px;
        border-radius: 10px;
        margin: 0 0px 20px 0;
    }
    &__openingtime {
        flex: 1 1 50%;
        background-color: #f2f2f2;
        padding: 20px;
        border-radius: 10px;
        display: flex;
        flex-wrap: wrap;
        margin: 0px 0 20px 0px;

        & h5 {
            width: 100%;
        }

        & p {
            width: 50%;
            padding: 0 0 0 0;

            &.--time {
                text-align: right;
            }
        }
    }

    &__address,
    &__phone {
        margin: 0 0 20px 0;
    }

    & .--in-progress {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .loader {
        border: 2px solid #f3f3f3; /* Light grey */
        border-top: 2px solid #3498db; /* Blue */
        border-radius: 50%;
        width: 20px;
        height: 20px;
        animation: spin 2s linear infinite;
        margin: 0 0 0 10px;
        &.--large {
            border: 20px solid #f3f3f3; /* Light grey */
            border-top: 20px solid #3498db; /* Blue */
            width: 120px;
            height: 120px;
            z-index: 20;
        }
    }

    &__error{
        & p{
            margin: 0;
        }
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .contact-us {
        &__info {
            flex-direction: row;
            & p {
                margin: 0 0 6px 0;
                line-height: 14px;
                font-size: 14px;
            }
        }

        &__action {
            width: 300px;
            margin: 0 auto;
        }

        &__info-section {
            margin: 0 20px 20px 0;
        }
        &__openingtime {
            margin: 0px 0 20px 20px;
        }

        &__map {
            margin: 40px 0;
        }
    }
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .contact-us {
        align-items: center;

        &__info {
            flex-direction: row;
            & p {
                margin: 0 0 6px 0;
                line-height: 14px;
                font-size: 14px;
            }
        }

        & .text-line1 {
            max-width: 710px;
            text-align: center;
        }

        &__section1,
        &__map {
            // flex-direction: row;
            max-width: 700px;
        }
    }
}
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .contact-us {
        & .text-line1 {
            max-width: 800px;
        }

        &__section1,
        &__map {
            // flex-direction: row;
            max-width: 800px;
        }
    }
}
